import React, { Fragment } from "react"
import moment from "moment"

import Section from "../../Elements/Section"
import FormDateV2 from "../../Elements/Form/FormDateV2"
import FormCheckbox from "../../Elements/Form/FormCheckbox"

const PreferredDeliveryScheduleField = ({ values, handleChange }) => {
  let earliestDeliveryDate = moment()
  const tenAM = moment().startOf("day").add(10, "hours")

  if (values?.deliveryAddress?.addressType === "Office")
    earliestDeliveryDate = earliestDeliveryDate.add(1, "days")

  if (
    values?.courierOption === "MedGrocer" &&
    earliestDeliveryDate.isAfter(tenAM)
  )
    earliestDeliveryDate.add(1, "day")

  return (
    <Fragment>
      <FormDateV2
        name="preferredDeliveryDate"
        label="Preferred Delivery Date"
        isRequired
        values={values?.preferredDeliveryDate}
        onChange={handleChange}
        config={{
          range: {
            minDate: earliestDeliveryDate.format("YYYY-MM-DD"),
            maxDate: moment().add(12, "months").format("YYYY-MM-DD"),
          },
          weekdays: true,
        }}
      />
      <FormCheckbox
        name="preferredDeliveryTime"
        values={values?.preferredDeliveryTime}
        options={["8AM - 12NN", "1PM - 5PM"]}
        title={"Preferred Delivery Time"}
        isRequired
      />
    </Fragment>
  )
}

const DeliveryScheduleSection = ({ isNationwide, values, handleChange }) => {
  return (
    <Section title="Delivery Schedule" className="my-1">
      <PreferredDeliveryScheduleField
        isNationwide={isNationwide}
        values={values}
        handleChange={handleChange}
      />
    </Section>
  )
}

export default DeliveryScheduleSection
